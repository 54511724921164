<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
        class="breadcrumb-area ptb--60 bg_image bg_dark"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Über uns</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                      item.text
                    }}
                  </router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start about Area -->

    <AboutThree></AboutThree>

    <!-- End about area -->

    <!-- Start Team Area  -->
    <div class="about-area rn-section-gap bg_color--1">
      <div class="about-wrapper">
        <v-container>
          <v-row align="center" class="row--35 team-wrapper mt-0">
            <v-col lg="6" md="6" cols="12">
              <div class="team-image-wrapper thumbnail">
                <img
                    v-on:mouseover="mouseOverFabio = true"
                    v-on:mouseout="mouseOverFabio = false"
                    v-bind:src="mouseOverFabio ? require(`../assets/images/about/Fabio_144p.gif`) : require(`@/assets/images/about/team_fabio_edited.jpg`)"
                    alt="About Images"
                />
              </div>
            </v-col>
            <v-col lg="6" md="6" cols="12">
              <div class="about-inner inner">
                <div class="section-title">
                  <h2 class="heading-title">Fabio Göldi</h2>
                  <h4 class="">Software Engineer</h4>
                  <p class="description">
                    Fabio ist das Brain von goeldi technology. Als Software Engineer ist er zuständig für die
                    Realisierung der Applikationen. Zusammen mit dir erarbeitet er eine individuelle Softwarelösung für
                    deine persönlichen Bedürfnisse.
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row align="center" class="row--35">
            <v-col lg="6" md="6" cols="12">
              <div class="about-inner inner">
                <div class="section-title">
                  <h2 class="heading-title">Ramona Rüttimann</h2>
                  <h4 class="">Creative Mind</h4>
                  <p class="description">
                    Ramona ist unser Gestaltungs-Genie. Sie unterstützt uns bei der Konzeption von Wireframes,
                    interaktiven Prototypen sowie bei der Umsetzung von Interface Designs und User Experience. Wenn sie
                    nicht gerade kreative Ideen für goeldi technology umsetzt, arbeitet sie in Zürich als Interaction Designerin.
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" cols="12">
              <div class="team-image-wrapper thumbnail">
                <img
                    v-on:mouseover="mouseOverRamona = true"
                    v-on:mouseout="mouseOverRamona = false"
                    v-bind:src="mouseOverRamona ? require(`../assets/images/about/Ramona_144p.gif`) : require(`../assets/images/about/team_ramona_edited.jpg`)"
                    alt="About Images"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- Start About Area  -->


    <Footer/>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import About from "../components/about/About";
import CounterOne from "../components/counter/CounterOne";
import TeamThree from "../components/team/TeamThree";
import Testimonial from "../components/testimonial/Testimonial";
import Brand from "../components/brand/Brand";
import Footer from "../components/footer/Footer";
import AboutTwo from "@/components/about/AboutTwo";
import AboutThree from "@/components/about/AboutThree";
import AboutFour from "@/components/about/AboutFour";

export default {
  components: {
    AboutTwo,
    AboutThree,
    AboutFour,
    Header,
    About,
    CounterOne,
    TeamThree,
    Testimonial,
    Brand,
    Footer,
  },
  data() {
    return {
      mouseOverFabio: false,
      mouseOverZarko: false,
      mouseOverRamona: false,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "Über uns",
          to: "",
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.team-wrapper {
  margin: 10em 0;
}

.team-image-wrapper img {
  width: 100%;
  filter: grayscale(1);
}

</style>
