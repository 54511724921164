<template>
  <v-container>
    <div>
      <v-row class="row--35" align="center">
        <v-col lg="12" md="12" cols="12" order="2" order-md="1" class="mt--150">
          <div class="about-inner inner">
            <div class="section-title">
              <h2 class="heading-title">goeldi technology.</h2>
              <p class="description mt-11 mb-7">
                goeldi technology ist ein junges innovatives Startup Unternehmen mit Sitz in St. Gallen. Die 2020 von
                Fabio Göldi gegründete Firma besteht aus einem Team von zwei Softwareentwicklern und unserem kreativen
                Köpfchen. Gemeinsam kreieren sie mittels eigener Software und Applikationen individuelle und persönliche
                Lösungen im IT-Bereich. goeldi technology liebt Herausforderungen und setzt bei der Umsetzung auf
                modernste Technologien und userfreundliches Design.
              </p>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <div class="mt--30">
                  <h3 class="heading-title">Davon sind wir überzeugt.</h3>
                  <ul class="list-style--1">
                    <li v-for="list in listOne" :key="list.id">
                      <i v-html="iconSvg(list.icon)" class="pt-2"></i>
                      {{ list.desc }}
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="pl-6">
                <div class="mt--30">
                  <h3 class="heading-title">Daran glauben wir.</h3>
                  <ul class="list-style--1">
                    <li v-for="list in listTwo" :key="list.id">
                      <i v-html="iconSvg(list.icon)" class="pt-2"></i>
                      {{ list.desc }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `IT-Lösungen sollten für alle zugänglich sein.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digitalisierung hat mehr Vor- als Nachteile.`,
        },
        {
          id: 3,
          icon: "check",
          desc: `Die richtigen Libraries und Frameworks verbessern das Produkt.`,
        },
        {
          id: 4,
          icon: "check",
          desc: `Software ist keine Hürde, sondern eine Stütze.`,
        },
      ],
      listTwo: [
        {
          id: 1,
          icon: "check",
          desc: `Teamwork.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Standards, best practice und state-of-the-art.`,
        },
        {
          id: 3,
          icon: "check",
          desc: `User Experience ist wegweisend.`,
        },
        {
          id: 4,
          icon: "check",
          desc: `Nachhaltigkeit für Umwelt und Code.`,
        },
      ],
      items: [
        {
          thumb: require("../../assets/images/about/about-3.png"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();

    },
  },
};
</script>
